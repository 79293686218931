




















































































































const fields = [
  { key: 'name', label: '이름', _style: 'width:8%' },
  { key: 'description', label: '설명', _style: 'width:15%' },
  { key: 'permissionNames', label: '권한 목록', _style: 'width:20%' },
  { key: 'status', label: '상태', _style: 'width:5%;' },
  {
    key: 'update',
    label: '',
    _style: 'width:5%',
    sorter: false,
    filter: false,
  },
];
const inactiveFields = [
  { key: 'checkBox', label: '', _style: 'width:1%' },
  { key: 'name', label: '이름', _style: 'width:8%' },
  { key: 'description', label: '설명', _style: 'width:15%' },
  { key: 'permissionNames', label: '권한 목록', _style: 'width:20%' },
  { key: 'status', label: '상태', _style: 'width:5%;' },
  {
    key: 'update',
    label: '',
    _style: 'width:5%',
    sorter: false,
    filter: false,
  },
];

import ContentCard from '@/components/FripComponents/ContentCard.vue';
import FripButton from '@/components/FripComponents/FripButton.vue';
import Vue, { VueConstructor } from 'vue'; // eslint-disable-line no-unused-vars
import { getAccountsOfRole } from '../../graphqls/account';
import { getRoles, deleteRole } from '../../graphqls/role';
import { Table, TableColumn, Pagination } from 'element-ui';
import Spinner from '@/components/Spinner.vue';

export default Vue.extend({
  name: 'RoleList',
  components: {
    ContentCard,
    FripButton,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Pagination.name]: Pagination,
    Spinner,
  },
  props: {
    status: String,
    noUpdate: Boolean,
    dataFilter: Function,
    title: String,
  },
  apollo: {
    roles: {
      query: getRoles,
      variables() {
        return {
          filter: this.$data.filter,
          page: this.$data.currentPage,
          pageSize: this.$data.pageSize,
        };
      },
      update(data) {
        this.$data.totalCount = data.authority.roles.totalCount;
        return data.authority.roles.edges.map((edge: any, index: number) => ({
          ...edge.node,
          permissionNames: edge.node.permissions.map((item: any) => item.name),
          index,
        }));
      },
    },
  },
  data() {
    return {
      searchWordTypeOptions: [
        { value: 'idLike', text: 'ID' },
        { value: 'nameLike', text: '역할명' },
        { value: 'permissionIn', text: '포함하는 권한의 ID' },
      ],
      searchFilter: {
        type: 'nameLike',
        value: '',
      },
      filter: { enabled: this.status === 'ACTIVE' },
      defaultFilter: { enabled: this.status === 'ACTIVE' },

      dirty: 0,
      warningModal: false,
      enableChecking: false,
      checkedRoles: [] as { id: string; index: number }[],
      fields,
      // roles,
      inactiveFields,
      details: [],
      collapseDuration: 0,
      checked: false,
      roles: [],
      totalCount: 1,
      pageSize: 10,
      currentPage: 1,
    };
  },
  methods: {
    search() {
      const type: string = this.$data.searchFilter.type;
      const value = this.$data.searchFilter.value;
      const filter: {
        [key: string]: any;
      } = { enabled: this.status === 'ACTIVE' };
      filter[type] = type === 'permissionIn' ? [value] : value;
      console.log(filter);
      this.$data.filter = filter;
    },
    reset() {
      this.$data.filter = this.$data.defaultFilter;
    },

    setPage(val: any) {
      this.$data.currentPage = val;
    },

    toogleExpand(row: any) {
      let $table = this.$refs.table;
      ($table as any).toggleRowExpansion(row);
    },

    checkAll() {
      this.$data.checked = !this.$data.checked;
      if (this.$data.checked) {
        const inactiveRoles = this.$data.roles.filter(
          (item: any) => item.status === 'INACTIVE'
        );
        for (var index = 0; index < inactiveRoles.length; index++) {
          this.$data.checkedRoles.push({
            id: (inactiveRoles[index] as any).id,
            index,
          });
        }
        console.log(this.$data.checkedRoles);
      } else {
        this.$data.checkedRoles = [];
      }
    },
    deletionWarning() {
      this.$data.warningModal = true;
    },
    async deleteCheckedRoles() {
      console.log(this.$data.checkedRoles);
      const unCheckedRoles = this.$data.roles.filter((role: any) =>
        this.$data.checkedRoles.every((checked: any) => checked.id !== role.id)
      );
      console.log(unCheckedRoles);
      for (var index in this.$data.checkedRoles) {
        console.log(index);
        console.log(this.$data.checkedRoles[index]);
        const res = await this.$apollo.mutate({
          mutation: deleteRole,
          variables: {
            id: this.$data.checkedRoles[index].id,
          },
        });
        console.log(res);
      }
      this.$data.roles = unCheckedRoles;
      this.$data.warningModal = false;
    },
    updateRole(item: any, index: number) {
      console.log(item);
      console.log(item.permissions);
      this.$router
        .push({
          name: 'Update Role',
          params: {
            id: item.id,
            name: item.name,
            description: item.description,
            createdAt: item.createdAt,
            updatedAt: item.updateAt,
            status: item.status,
            permissionsOfRole: item.permissions,
          },
        })
        .catch(error => {
          if (error.name != 'NavigationDuplicated') {
            throw error;
          }
        });
      console.log(item, index);
    },

    getBadge(status: string) {
      switch (status) {
        case 'ACTIVE':
          return 'success';
        case 'INACTIVE':
          return 'secondary';
        default:
          'primary';
      }
    },
  },
});
