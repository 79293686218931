































































































































const permissionFields = [
  { key: 'name', label: '권한명', _style: 'width:20%' },
  { key: 'description', label: '상세 설명', _style: 'width:20%' },
  {
    key: 'select',
    label: '',
    _style: 'width:20%',
    sorter: false,
    filter: false,
  },
];
const selectedPermissionFields = [
  { key: 'name', label: '권한명', _style: 'width:20%' },
  { key: 'description', label: '상세 설명', _style: 'width:20%' },
  {
    key: 'delete',
    label: '',
    _style: 'width:20%',
    sorter: false,
    filter: false,
  },
];

import Vue from 'vue';
import { getPermissions } from '../../graphqls/permission';
import {
  attachPermissionsToRole,
  detachPermissionsFromRole,
  updateRole,
  disableRole,
  enableRole,
} from '../../graphqls/role';
import { getAccountsOfRole } from '../../graphqls/account';
import ContentCard from '@/components/FripComponents/ContentCard.vue';
import InlineInput from '@/components/Forms/InlineInput.vue';
import FripButton from '@/components/FripComponents/FripButton.vue';
import { Table, TableColumn } from 'element-ui';
import PermissionList from '../permission/Permissions.vue';
import InputRow from '@/components/Forms/InputRow.vue';

export default Vue.extend({
  components: {
    ContentCard,
    InlineInput,
    FripButton,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    PermissionList,
    InputRow,
  },
  props: {
    id: String,
    name: String,
    description: String,
    permissionsOfRole: Array,
    status: String,
  },

  apollo: {
    permissions: {
      query: getPermissions,
      variables() {
        return {};
      },
      update: data =>
        data.authority.permissions.edges.map((edge: any, index: any) => ({
          ...edge.node,
          index,
        })),
    },
    accountsOfRole: {
      query: getAccountsOfRole,
      variables() {
        return {
          filter: {
            roleIn: [this.id],
          },
        };
      },
      update: data => ({
        totalCount: data.authority.accounts.totalCount,
        departmentsOfUsers:
          data.authority.accounts.edges.map(
            (item: any) => item.node.department
          ) || [],
      }),
    },
  },
  data() {
    return {
      enable: this.status === 'ACTIVE',
      permissionFields,
      selectedPermissionFields,
      selectedPermissions: [...this.permissionsOfRole],
      permissions: [],
    };
  },

  methods: {
    toogleExpand(row: any) {
      //이부분 ref 해결해야함
      let $table = this.$refs.table;
      ($table as any).toggleRowExpansion(row);
    },

    countDepartment(departments: any[]) {
      const result = new Map<string, number>();
      for (const i in departments) {
        const department = departments[i];
        console.log(result.get(department));
        result.set(department, (result.get(department) || 0) + 1);
      }
      return Object.fromEntries(result);
    },
    selectPermission(permission: any) {
      this.$data.selectedPermissions.push(permission);
    },
    deletePermission(index: number) {
      this.$data.selectedPermissions.splice(index, 1); //dog,cat,seal,lion,cat
    },
    async updateRole() {
      const preStatus = this.status === 'ACTIVE';
      if (this.$data.enable !== preStatus) {
        if (!this.$data.enable) {
          const res0 = await this.$apollo.mutate({
            mutation: disableRole,
            variables: {
              id: this.id,
            },
          });
          console.log(res0);
        } else {
          const res0 = await this.$apollo.mutate({
            mutation: enableRole,
            variables: {
              id: this.id,
            },
          });
          console.log(res0);
        }
      }
      console.log(this.$data.selectedPermissions);
      console.log(this.$props.permissionsOfRole);
      const permissionsForAttach = this.$data.selectedPermissions.filter(
        (selected: any) =>
          this.permissionsOfRole.every(
            (existed: any) => existed.id !== selected.id
          )
      );
      const permissionsForDetach = this.$props.permissionsOfRole.filter(
        (existed: any) =>
          this.$data.selectedPermissions.every(
            (selected: any) => existed.id !== selected.id
          )
      );
      const res1 = await this.$apollo.mutate({
        mutation: updateRole,
        variables: {
          param: {
            id: this.id,
            name: this.name,
            description: this.description,
          },
        },
      });
      if (permissionsForAttach.length) {
        const res2 = await this.$apollo.mutate({
          mutation: attachPermissionsToRole,
          variables: {
            authorityRoleId: this.id,
            permissionIds: permissionsForAttach.map(
              (permission: any) => permission.id
            ),
          },
        });
        console.log(res2);
      }
      if (permissionsForDetach.length) {
        const res3 = await this.$apollo.mutate({
          mutation: detachPermissionsFromRole,
          variables: {
            authorityRoleId: this.id,
            permissionIds: permissionsForDetach.map(
              (permission: any) => permission.id
            ),
          },
        });
        console.log(res3);
      }
      this.$router
        .push({
          name: 'Role List',
        })
        .catch(error => {
          if (error.name != 'NavigationDuplicated') {
            throw error;
          }
        });
    },
  },
});
