















import Vue from 'vue';
import Permissions from './Permissions.vue';
import { Tabs, TabPane } from 'element-ui';
export default Vue.extend({
  name: 'PermissionList',
  components: {
    Permissions,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
  },
});
