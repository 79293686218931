
















































import Vue from 'vue';
import { getAuthUrl } from '../../../../env';
import axios from 'axios';
export default Vue.extend({
  data(): {
    email: string;
    name: string;
    department: string;
    password: string;
  } {
    return {
      name: '',
      email: '',
      department: '',
      password: '',
    };
  },
  methods: {
    async request() {
      const accessToken = this.$store.state.accessToken;
      try {
        await axios({
          url: `${getAuthUrl()}/signup/admin`,
          data: {
            name: this.name,
            password: this.password,
            department: this.department,
            email: this.email + '@frientrip.com',
          },
          headers: { authorization: `bearer ${accessToken}` },
          method: 'POST',
        });
        this.$modal.show({
          title: '계정 요청 성공!',
          message: '',
          type: 'success',
        });
      } catch (err) {
        this.$modal.show({
          title: '계정 요청 실패!',
          message: '',
          type: 'warning',
        });
      }
    },
  },
});
