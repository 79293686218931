import gql from 'graphql-tag';

//Queries
export const getRoles = gql`
  query getRoles(
    $page: Int
    $pageSize: Int
    $filter: AuthorityRoleFilterParam
  ) {
    authority {
      roles(page: $page, pageSize: $pageSize, filter: $filter) {
        totalCount
        edges {
          node {
            id
            name
            description
            status
            permissions {
              id
              name
              description
              items {
                id
                field
                type
              }
            }
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;
export const getRolesForCount = gql`
  query getRolesForCount(
    $page: Int
    $pageSize: Int
    $filter: AuthorityRoleFilterParam
  ) {
    authority {
      roles(page: $page, pageSize: $pageSize, filter: $filter) {
        totalCount
      }
    }
  }
`;

export const getRole = gql`
  query getRole($id: ID!) {
    authority {
      role(id: $id) {
        id
        name
        description
        status
        permissions {
          id
          name
          description
          items {
            id
            field
            type
          }
        }
      }
    }
  }
`;

//Mutations

export const createRole = gql`
  mutation createRole($param: AuthorityRoleParam!) {
    createAuthorityRole(param: $param) {
      id
    }
  }
`;
export const deleteRole = gql`
  mutation deleteRole($id: ID!) {
    deleteAuthorityRole(id: $id)
  }
`;
export const enableRole = gql`
  mutation enableRole($id: ID!) {
    enableAuthorityRole(id: $id) {
      id
    }
  }
`;
export const disableRole = gql`
  mutation disableRole($id: ID!) {
    disableAuthorityRole(id: $id) {
      id
    }
  }
`;
export const attachPermissionsToRole = gql`
  mutation attachPermissionsToRole(
    $authorityRoleId: ID!
    $permissionIds: [ID!]!
  ) {
    attachPermissionsToAuthorityRole(
      authorityRoleId: $authorityRoleId
      permissionIds: $permissionIds
    ) {
      id
    }
  }
`;
export const detachPermissionsFromRole = gql`
  mutation detachPermissionsFromRole(
    $authorityRoleId: ID!
    $permissionIds: [ID!]!
  ) {
    detachPermissionsFromAuthorityRole(
      authorityRoleId: $authorityRoleId
      permissionIds: $permissionIds
    ) {
      id
    }
  }
`;

export const updateRole = gql`
  mutation updateRole($param: AuthorityRoleParam!) {
    updateAuthorityRole(param: $param) {
      id
    }
  }
`;
