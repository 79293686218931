






















































































const fields = [
  { key: 'name', label: '이름', _style: 'width:8%' },
  { key: 'description', label: '설명', _style: 'width:25%' },
  { key: 'status', label: '상태', _style: 'width:5%;' },
  {
    key: 'show_details',
    label: '',
    _style: 'width:2%',
    sorter: false,
    filter: false,
  },
  {
    key: 'update',
    label: '',
    _style: 'width:2%',
    sorter: false,
    filter: false,
  },
];
const permissionItemFields = [
  { key: 'type', label: '타입', _style: 'width:20%' },
  { key: 'field', label: '필드', _style: 'width:20%' },
];

import Vue, { VueConstructor } from 'vue'; // eslint-disable-line no-unused-vars
import { getPermissions } from '../../graphqls/permission';
import { computePages } from '../../components/utils';
import ContentCard from '@/components/FripComponents/ContentCard.vue';
import FripButton from '@/components/FripComponents/FripButton.vue';
import { Table, TableColumn, Pagination } from 'element-ui';
import Spinner from '@/components/Spinner.vue';

export default Vue.extend({
  name: 'PermissionList',
  components: {
    ContentCard,
    FripButton,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Pagination.name]: Pagination,
    Spinner,
  },
  props: {
    status: String,
    dataFilter: Function,
    noUpdate: Boolean,
    title: String,
  },
  data() {
    return {
      fields,
      searchWordTypeOptions: [
        { value: 'fieldLike', text: '필드명' },
        { value: 'nameLike', text: '권한명' },
        { value: 'typeLike', text: '타입명' },
      ],
      searchFilter: {
        type: 'nameLike',
        value: '',
      },
      filter: { enabled: this.status === 'ACTIVE' },
      defaultFilter: { enabled: this.status === 'ACTIVE' },

      permissionItemFields,
      details: [],
      collapseDuration: 0,
      permissions: [],
      currentPage: 1,
      pageSize: 10,
      totalCount: 1,
      // endCursor: '',
      // hasNextPage: false,
    };
  },
  apollo: {
    permissions: {
      query: getPermissions,
      variables() {
        return {
          filter: this.$data.filter,
          page: this.$data.currentPage,
          pageSize: this.$data.pageSize,
        };
      },
      update(data) {
        // this.endCursor = data.authority.permissions.pageInfo.endCursor;
        // console.log(this.endCursor);
        // this.hasNextPage = data.authority.permissions.pageInfo.hasNextPage;
        this.$data.totalCount = data.authority.permissions.totalCount;
        console.log(this.$data.totalCount);
        console.log(
          data.authority.permissions.edges.map((edge: any, index: number) => ({
            ...edge.node,
            index,
          }))
        );
        this.$data.totalPage = computePages(
          data.authority.permissions.totalCount,
          this.$data.pageSize
        );
        return data.authority.permissions.edges.map(
          (edge: any, index: number) => ({
            ...edge.node,
            index,
          })
        );
      },
    },
  },
  methods: {
    search() {
      const type: string = this.$data.searchFilter.type;
      const value = this.$data.searchFilter.value;
      const filter: {
        [key: string]: any;
      } = { enabled: this.status === 'ACTIVE' };
      filter[type] = value;
      console.log(filter);
      this.$data.filter = filter;
    },
    reset() {
      this.$data.filter = this.$data.defaultFilter;
    },

    setPage(val: any) {
      this.$data.currentPage = val;
    },

    toogleExpand(row: any) {
      //이부분 ref 해결해야함
      let $table = this.$refs.table;
      ($table as any).toggleRowExpansion(row);
    },

    updatePermission(item: any, index: number) {
      this.$router
        .push({
          name: 'Update Permission',
          params: {
            id: item.id,
            name: item.name,
            description: item.description,
            status: item.status,
            permissionItems: item.items,
          },
        })
        .catch(error => {
          if (error.name != 'NavigationDuplicated') {
            throw error;
          }
        });
    },
    getBadge(status: string) {
      switch (status) {
        case 'ACTIVE':
          return 'success';
        case 'INACTIVE':
          return 'secondary';
        default:
          'primary';
      }
    },

    toggleDetails(item: any) {
      this.$set(this.$data.permissions[item.index], '_toggled', !item._toggled);
      this.$data.collapseDuration = 300;
      this.$nextTick(() => {
        this.$data.collapseDuration = 0;
      });
    },
  },
});
