






























































































































const userFields = [
  { key: 'nickname', _style: 'width:20%' },
  'email',
  {
    key: 'select',
    label: '',
    _style: 'width:20%',
    sorter: false,
    filter: false,
  },
];
const roleFields = [
  { key: 'name', label: '역할명', _style: 'width:20%' },
  { key: 'description', label: '상세 설명', _style: 'width:20%' },
  { key: 'permissionNames', label: '포함 권한 목록', _style: 'width:20%' },
  {
    key: 'select',
    label: '',
    _style: 'width:20%',
    sorter: false,
    filter: false,
  },
];
const selectedRoleFields = [
  { key: 'name', label: '역할명', _style: 'width:20%' },
  { key: 'permissionNames', label: '포함 권한 목록', _style: 'width:20%' },
  {
    key: 'delete',
    label: '',
    _style: 'width:20%',
    sorter: false,
    filter: false,
  },
];
import Vue from 'vue';
import RoleList from '../role/Roles.vue';
import { getUsers } from '../../graphqls/user';
import { getRoles } from '../../graphqls/role';
import BaseSwitch from '@/BaseComponents/BaseSwitch.vue';
import FripButton from '@/components/FripComponents/FripButton.vue';
import { Table, TableColumn } from 'element-ui';
import ContentCard from '@/components/FripComponents/ContentCard.vue';
import InlineInput from '@/components/Forms/InlineInput.vue';
import TermInput from '@/components/Forms/TermInput.vue';
import { AccountService } from '../../service/AccountService';
import { apolloClient } from '@/apolloClient';
import { AuthorityRole, AuthorityRoleEdge } from '../../model/role';
import { Permission } from '../../model/permission';

const accountService = new AccountService(apolloClient);

export default Vue.extend({
  components: {
    RoleList,
    BaseSwitch,
    FripButton,
    ContentCard,
    InlineInput,
    TermInput,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    id: String,
    name: String,
    department: String,
    rolesOfUser: Array,
    status: String,
    activated: Boolean,
    email: String,
    activatedAt: String,
    expiredAt: String,
  },
  apollo: {
    roles: {
      query: getRoles,
      variables() {
        return {};
      },
      update: data =>
        data.authority.roles.edges.map(
          (edge: AuthorityRoleEdge, index: number) => ({
            ...edge.node,
            permissionNames: edge.node.permissions.map(
              (item: Permission) => item.name
            ),
            index,
          })
        ),
    },
    users: {
      query: getUsers,
      variables() {
        return {
          filter: { nicknameLike: this.$data.result },
        };
      },
      update: data => {
        console.log('getUsers');
        data.users.edges.map(
          (edge: { node: { id: string } }, index: number) => ({
            ...edge.node,
            index,
          })
        );
      },
    },
  },
  data() {
    return {
      roles: [],
      accounts: [],
      enable: this.status === 'ACTIVE',
      result: '',
      nickName: '',
      activeTerm: {
        startedAt: this.activatedAt || 0,
        endedAt: this.expiredAt || 0,
      },
      roleFields,
      userFields,
      selectedRoleFields,
      selectedRoles:
        this.rolesOfUser.map((role: any) => ({
          ...role,
          permissionNames: role.permissions.map(
            (permission: Permission) => permission.name
          ),
        })) || [],
    };
  },
  methods: {
    search(name: string) {
      console.log(name);
      this.$data.result = name;
    },
    selectRole(role: AuthorityRole) {
      console.log(this.$data.startedAt);
      this.$data.selectedRoles.push(role);
      console.log(this.$data.selectedRoles);
    },
    deleteRole(index: number) {
      this.$data.selectedRoles.splice(index, 1);
      console.log(this.$data.selectedRoles);
    },
    async updateAccount() {
      const preStatus = this.status === 'ACTIVE';
      console.log(this.$data);
      if (this.$data.enable !== preStatus) {
        if (!this.$data.enable) {
          await accountService.disable(this.$props.id);
        } else {
          await accountService.enable(this.$props.id);
        }
      }
      const rolesForAttach = this.$data.selectedRoles.filter(
        (role: AuthorityRole) =>
          this.rolesOfUser.every((roleOfUser: any) => roleOfUser.id !== role.id)
      );
      const rolesForDetach = this.rolesOfUser.filter((roleOfUser: any) =>
        this.$data.selectedRoles.every(
          (role: AuthorityRole) => roleOfUser.id !== role.id
        )
      );
      console.log(rolesForAttach, rolesForDetach);

      await accountService.updateBaseInfo({
        id: this.id,
        name: this.name,
        department: this.department,
        email: this.email,
      });
      console.log(rolesForAttach.map((role: AuthorityRole) => role.id));
      if (rolesForAttach.length) {
        await accountService.attachRoles(this.id, rolesForAttach);
      }
      if (rolesForDetach.length) {
        await accountService.detachRoles(
          this.id,
          rolesForDetach as AuthorityRole[]
        );
      }
      console.log(
        this.$data.activeTerm.startedAt,
        this.$data.activeTerm.endedAt
      );
      console.log(this.activatedAt, this.expiredAt);
      if (
        this.$data.activeTerm.startedAt &&
        this.$data.activeTerm.endedAt &&
        (this.$data.activeTerm.startedAt !== this.activatedAt ||
          this.$data.activeTerm.endedAt !== this.expiredAt)
      ) {
        //시작, 끝 시간 관련 조건문 추가해야함
        console.log(this.$data.activeTerm);
        await accountService.applyTerm(
          this.id,
          this.$data.activeTerm.startedAt,
          this.$data.activeTerm.endedAt
        );
      }
      this.$router
        .push({
          name: 'Account List',
        })
        .catch(error => {
          if (error.name != 'NavigationDuplicated') {
            throw error;
          }
        });
    },
  },
});
