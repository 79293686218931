















import Vue from 'vue';
import Accounts from './Accounts.vue';
import { Tabs, TabPane } from 'element-ui';

export default Vue.extend({
  name: 'AccountList',
  components: {
    Accounts,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
  },
});
