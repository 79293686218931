















import Vue from 'vue';
import Roles from './Roles.vue';
import { Tabs, TabPane } from 'element-ui';
export default Vue.extend({
  name: 'RoleList',
  components: {
    Roles,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
  },
});
