



































































































































































































import Vue from 'vue';
import RoleList from '../role/Roles.vue';
import { getUsers } from '../../graphqls/user';
import { createAccount } from '../../graphqls/account';
import { getRoles } from '../../graphqls/role';
import { attachRolesToAccount } from '../../graphqls/account';
import FripButton from '@/components/FripComponents/FripButton.vue';
import InlineInput from '@/components/Forms/InlineInput.vue';
import { Table, TableColumn, Pagination } from 'element-ui';
import ContentCard from '@/components/FripComponents/ContentCard.vue';
import Spinner from '@/components/Spinner.vue';
import { apolloClient } from '@/apolloClient';
import { AuthorityRole, AuthorityRoleEdge } from '../../model/role';
import { Permission } from '../../model/permission';
import { AccountService } from '../../service/AccountService';
import InputRow from '@/components/Forms/InputRow.vue';

const accountService = new AccountService(apolloClient);

export default Vue.extend({
  apollo: {
    roles: {
      query: getRoles,
      variables() {
        return {};
      },
      update: data =>
        data.authority.roles.edges.map(
          (edge: AuthorityRoleEdge, index: number) => ({
            ...edge.node,
            permissionNames: edge.node.permissions.map(
              (item: Permission) => item.name
            ),
            index,
          })
        ),
    },

    users: {
      query: getUsers,
      variables() {
        return {
          page: this.$data.currentPage,
          pageSize: this.$data.pageSize,
          filter: {
            emailLike: this.$data.result.email,
            nicknameLike: this.$data.result.nickname,
          },
        };
      },
      update(data) {
        this.$data.totalCount = data.users.totalCount;
        return {
          hasNextPage: data.users.pageInfo.hasNextPage,
          edges: data.users.edges.map(
            (
              edge: { node: { nickname: string; email: string } },
              index: number
            ) => ({
              ...edge.node,
              index,
            })
          ),
        };
      },
    },
  },
  components: {
    RoleList,
    FripButton,
    InlineInput,
    ContentCard,
    Spinner,
    InputRow,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Pagination.name]: Pagination,
  },
  data() {
    return {
      form: {
        id: '',
        email: '',
        name: '',
        department: '',
      },
      isShow: false,
      result: { nickname: '', email: '' },
      selectedRoles: [],
      roles: [],
      currentPage: 1,
      pageSize: 10,
      totalCount: 1,
      searchForm: { nickname: '', email: '' },
    };
  },
  methods: {
    changeKeyword(e: string) {
      this.$data.searchForm.email = e;
    },
    setPage(val: number) {
      this.$data.currentPage = val;
    },
    search(searchForm: { email: string; nickname: string }) {
      console.log(searchForm);
      this.$data.result = { ...searchForm };
      this.$data.currentPage = 1;
    },
    selectUser(user: { id: string; email: string; nickname: string }) {
      console.log(user);
      this.$data.form = {
        email: user.email,
        name: user.nickname,
        id: user.id,
        department: '',
      };
      console.log(this.$data.form);
      this.$data.isShow = false;
    },
    selectRole(role: AuthorityRole, index: number) {
      this.$data.selectedRoles.push(role);
    },
    deleteRole(index: number) {
      this.$data.selectedRoles.splice(index, 1); //dog,cat,seal,lion,cat
    },
    async createAccount() {
      await accountService.create(this.$data.form, this.$data.selectedRoles);
      this.$router
        .push({
          name: 'Account List',
        })
        .catch(error => {
          if (error.name != 'NavigationDuplicated') {
            throw error;
          }
        });
    },
  },
});
