





















































































































const selectedPermissionItemsField = [
  { key: 'type', label: '타입' },
  { key: 'field', label: '필드' },
  { key: 'delete', label: '' },
];
import {
  updatePermission,
  enablePermission,
  disablePermission,
} from '../../graphqls/permission';
import { getRolesForCount } from '../../graphqls/role';
import Vue from 'vue';
import ContentCard from '@/components/FripComponents/ContentCard.vue';
import FripButton from '@/components/FripComponents/FripButton.vue';
import InlineInput from '@/components/Forms/InlineInput.vue';
import { PermissionItem } from '../../model/permission';

export default Vue.extend({
  components: {
    ContentCard,
    FripButton,
    InlineInput,
  },
  props: {
    id: String,
    name: String,
    description: String,
    permissionItems: Array,
    status: String,
  },
  apollo: {
    totalCount: {
      query: getRolesForCount,
      variables() {
        return {
          filter: {
            permissionIn: [this.id],
          },
        };
      },
      update: data => data.authority.roles.totalCount,
    },
  },

  data() {
    return {
      type: '',
      field: '',
      enable: this.status === 'ACTIVE',
      selectedPermissionItemsField,
      selectedPermissionItems: [...this.permissionItems],
    };
  },
  methods: {
    deletePermissionItem(index: number) {
      this.$data.selectedPermissionItems.splice(index, 1); //dog,cat,seal,lion,cat
    },
    addPermissionItem(type: string, field: string) {
      const permissionItem = { type, field };
      this.$data.selectedPermissionItems.push(permissionItem);
    },

    async updatePermission() {
      const preStatus = this.status === 'ACTIVE';
      if (this.$data.enable !== preStatus) {
        if (!this.$data.enable) {
          const res0 = await this.$apollo.mutate({
            mutation: disablePermission,
            variables: {
              id: this.id,
            },
          });
          console.log(res0);
        } else {
          const res0 = await this.$apollo.mutate({
            mutation: enablePermission,
            variables: {
              id: this.id,
            },
          });
          console.log(res0);
        }
      }

      const res1 = await this.$apollo.mutate({
        mutation: updatePermission,
        variables: {
          param: {
            id: this.id,
            name: this.name,
            description: this.description,
            items: this.$data.selectedPermissionItems.map(
              (item: PermissionItem) => ({
                type: item.type,
                field: item.field,
              })
            ),
          },
        },
      });
      console.log(res1);
      this.$router
        .push({
          name: 'Permission List',
        })
        .catch(error => {
          if (error.name != 'NavigationDuplicated') {
            throw error;
          }
        });
    },
  },
});
