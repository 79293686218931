


















































































































const permissionFields = [
  { key: 'name', label: '권한명', _style: 'width:30%' },
  { key: 'description', label: '상세', _style: 'width:40%' },
  {
    key: 'show_details',
    label: '',
    _style: 'width:15%',
    sorter: false,
    filter: false,
  },

  {
    key: 'select',
    label: '',
    _style: 'width:15%',
    sorter: false,
    filter: false,
  },
];
const selectedPermissionFields = [
  { key: 'name', label: '권한명', _style: 'width:20%' },
  { key: 'description', label: '상세', _style: 'width:20%' },
  {
    key: 'delete',
    label: '',
    _style: 'width:20%',
    sorter: false,
    filter: false,
  },
];
const permissionItemFields = [
  { key: 'type', label: '타입', _style: 'width:20%' },
  { key: 'field', label: '필드', _style: 'width:20%' },
];

import InlineInput from '@/components/Forms/InlineInput.vue';
import ContentCard from '@/components/FripComponents/ContentCard.vue';
import Vue from 'vue';
import { getPermissions } from '../../graphqls/permission';
import { attachPermissionsToRole, createRole } from '../../graphqls/role';
import { Table, TableColumn } from 'element-ui';
import FripButton from '@/components/FripComponents/FripButton.vue';
import PermissionList from '../permission/Permissions.vue';

export default Vue.extend({
  components: {
    ContentCard,
    InlineInput,
    FripButton,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    PermissionList,
  },
  data() {
    return {
      id: '',
      description: '',
      name: '',
      permissionFields,
      selectedPermissionFields,
      selectedPermissions: [],
      permissionItemFields,
      collapseDuration: 0,
      permissions: [],
    };
  },
  apollo: {
    permissions: {
      query: getPermissions,
      variables() {
        return {};
      },
      update: data =>
        data.authority.permissions.edges.map((edge: any, index: any) => ({
          ...edge.node,
          index,
        })),
    },
  },
  methods: {
    selectPermission(permission: any) {
      if (
        (this as any).selectedPermissions.every(
          (selected: any) => selected.id != permission.id
        )
      ) {
        (this as any).selectedPermissions.push(permission);
      }
    },
    deletePermission(index: number) {
      (this as any).selectedPermissions.splice(index, 1); //dog,cat,seal,lion,cat
    },
    async createRole() {
      console.log(
        (this as any).id,
        (this as any).name,
        (this as any).description,

        (this as any).selectedPermissions.map(
          (permission: any) => permission.id
        )
      );

      const res1 = await (this as any).$apollo.mutate({
        mutation: createRole,
        variables: {
          param: {
            id: (this as any).id,
            name: (this as any).name,
            description: (this as any).description,
          },
        },
      });
      console.log(res1);
      if (this.selectedPermissions.length) {
        const res2 = await (this as any).$apollo.mutate({
          mutation: attachPermissionsToRole,
          variables: {
            authorityRoleId: (this as any).id,
            permissionIds: (this as any).selectedPermissions.map(
              (permission: any) => permission.id
            ),
          },
        });
      }
      this.$router
        .push({
          name: 'Role List',
        })
        .catch(error => {
          if (error.name != 'NavigationDuplicated') {
            throw error;
          }
        });
    },
    toogleExpand(row: any) {
      //이부분 ref 해결해야함
      let $table = this.$refs.table;
      ($table as any).toggleRowExpansion(row);
    },
  },
});
