import gql from 'graphql-tag';

//Queries
export const getUsers = gql`
  query getUsers($page: Int, $pageSize: Int, $filter: UserFilter) {
    users(page: $page, size: $pageSize, filter: $filter) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          nickname
          id
          email
        }
      }
    }
  }
`;
