import gql from 'graphql-tag';

//Queries
export const getAccounts = gql`
  query getAccounts(
    $page: Int
    $pageSize: Int
    $filter: AuthorityAccountFilterParam
  ) {
    authority {
      accounts(page: $page, pageSize: $pageSize, filter: $filter) {
        totalCount
        pageInfo {
          hasNextPage
        }
        edges {
          node {
            id
            name
            department
            email
            expiredAt
            activatedAt
            roles {
              id
              name
              description
              permissions {
                id
                name
              }
            }
            status
            activated
          }
        }
      }
    }
  }
`;

export const getAccountsForCount = gql`
  query getAccountsForCount(
    $page: Int
    $pageSize: Int
    $filter: AuthorityAccountFilterParam
  ) {
    authority {
      accounts(page: $page, pageSize: $pageSize, filter: $filter) {
        totalCount
      }
    }
  }
`;

export const getAccountsOfRole = gql`
  query getAccountsOfRole(
    $page: Int
    $pageSize: Int
    $filter: AuthorityAccountFilterParam
  ) {
    authority {
      accounts(page: $page, pageSize: $pageSize, filter: $filter) {
        totalCount
        edges {
          node {
            department
          }
        }
      }
    }
  }
`;

export const getAccount = gql`
  query getAccount($id: ID!) {
    authority {
      account(id: $id) {
        id
      }
    }
  }
`;

//Mutations
export const createAccount = gql`
  mutation createAccount($param: AuthorityAccountParam!) {
    createAuthorityAccount(param: $param) {
      id
    }
  }
`;
export const enableAccount = gql`
  mutation enableAccount($id: ID!) {
    enableAuthorityAccount(id: $id) {
      id
    }
  }
`;
export const disableAccount = gql`
  mutation disableAccount($id: ID!) {
    disableAuthorityAccount(id: $id) {
      id
    }
  }
`;
export const applyTermOfAccount = gql`
  mutation applyTermOfAccount(
    $id: ID!
    $activatedAt: Instant!
    $expiredAt: Instant!
  ) {
    applyTermOfAuthorityAccount(
      id: $id
      activatedAt: $activatedAt
      expiredAt: $expiredAt
    ) {
      id
    }
  }
`;
export const attachRolesToAccount = gql`
  mutation attachRolesToAccount($accountId: ID!, $roleIds: [ID!]!) {
    attachRolesToAuthorityAccount(accountId: $accountId, roleIds: $roleIds) {
      id
    }
  }
`;

export const detachRolesFromAccount = gql`
  mutation detachRolesFromAccount($accountId: ID!, $roleIds: [ID!]!) {
    detachRolesFromAuthorityAccount(accountId: $accountId, roleIds: $roleIds) {
      id
    }
  }
`;

export const updateAccount = gql`
  mutation updateAccount($param: AuthorityAccountParam!) {
    updateAuthorityAccount(param: $param) {
      id
    }
  }
`;

export const deleteAccount = gql`
  mutation deleteAccount($id: ID!) {
    deleteAuthorityAccount(id: $id)
  }
`;
