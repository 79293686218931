










































































































const permissionItemFields = [
  { key: 'type', label: '타입', _style: 'width:20%' },
  { key: 'field', label: '필드', _style: 'width:20%' },
  {
    key: 'delete',
    label: '',
    _style: 'width:20%',
    sorter: false,
    filter: false,
  },
];

import { createPermission } from '../../graphqls/permission';
import Vue from 'vue';
import ContentCard from '@/components/FripComponents/ContentCard.vue';
import FripButton from '@/components/FripComponents/FripButton.vue';
import InlineInput from '@/components/Forms/InlineInput.vue';
import { Table, TableColumn } from 'element-ui';

export default Vue.extend({
  components: {
    ContentCard,
    FripButton,
    InlineInput,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data: function () {
    return {
      type: '',
      field: '',
      permissionItemFields,
      id: '',
      name: '',
      description: '',
      permissionItems: [],
    };
  },
  methods: {
    addPermissionItem(type: string, field: string) {
      console.log({ type: type, field: field });
      console.log(this.permissionItems);
      const permissionItem = { type, field };
      this.$data.permissionItems.push(permissionItem);
      console.log(this.permissionItems);
    },
    deletePermissionItem(index: number) {
      this.$data.permissionItems.splice(index, 1); //dog,cat,seal,lion,cat
    },

    async createPermission() {
      console.log(
        this.id,
        this.name,
        this.description,
        this.permissionItems.slice(1)
      );
      const res1 = await this.$apollo.mutate({
        mutation: createPermission,
        variables: {
          param: {
            id: this.id,
            name: this.name,
            description: this.description,
            items: this.permissionItems,
          },
        },
      });
      console.log(res1);
      this.$router
        .push({
          name: 'Permission List',
        })
        .catch(error => {
          if (error.name != 'NavigationDuplicated') {
            throw error;
          }
        });
    },
  },
});
