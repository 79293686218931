



















































































































import Vue, { VueConstructor } from 'vue'; // eslint-disable-line no-unused-vars
import { getAccounts } from '../../graphqls/account';
import { computePages } from '../../components/utils';
import { Table, TableColumn, Pagination } from 'element-ui';
import Spinner from '@/components/Spinner.vue';
import {
  AuthorityAccount,
  AuthorityAccountFilterParam,
  AuthorityAccountEdge,
} from '../../model/account';
import { AuthorityRole } from '../../model/role';
import ContentCard from '@/components/FripComponents/ContentCard.vue';
import { AccountService } from '../../service/AccountService';
import { apolloClient } from '@/apolloClient';
const accountService = new AccountService(apolloClient);

export default Vue.extend({
  name: 'AccountList',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Pagination.name]: Pagination,
    ContentCard,
    Spinner,
  },
  props: {
    status: String,
    noUpdate: Boolean,
  },
  data(): {
    searchWordTypeOptions: { value: string; text: string }[];
    searchFilter: { type: string; value: string };
    checked: boolean;
    filter: AuthorityAccountFilterParam;
    checkedAccounts: { id: string; index: number }[];
    accounts: AuthorityAccount[];
    totalCount: number;
    totalPage: number;
    currentPage: number;
    pageSize: number;
    defaultFilter: AuthorityAccountFilterParam;
  } {
    return {
      searchWordTypeOptions: [
        { value: 'departmentLike', text: '부서' },
        { value: 'idLike', text: 'ID' },
        { value: 'nameLike', text: '이름' },
        { value: 'roleIn', text: '포함하는 역할의 ID' },
      ],
      searchFilter: {
        type: 'nameLike',
        value: '',
      },
      checked: false,
      filter: { enabled: this.status === 'ACTIVE' },
      checkedAccounts: [],
      accounts: [],
      totalCount: 1,
      totalPage: 1,
      currentPage: 1,
      pageSize: 10,
      defaultFilter: { enabled: this.status === 'ACTIVE' },
    };
  },
  apollo: {
    accounts: {
      query: getAccounts,
      variables() {
        return {
          filter: this.$data.filter,
          page: this.$data.currentPage,
          pageSize: this.$data.pageSize,
        };
      },
      // update:(date)=> 와의 차이점은 .. ?
      update(data) {
        console.log(data);
        this.$data.totalPage = computePages(
          data.authority.accounts.totalCount,
          this.$data.pageSize
        );
        console.log(this.$data.totalPage);

        this.$data.totalCount = data.authority.accounts.totalCount;
        console.log(data.totalCount);
        return data.authority.accounts.edges.map(
          (edge: AuthorityAccountEdge, index: number) => ({
            ...edge.node,
            roleNames: edge.node.roles.map((role: AuthorityRole) => role.name),
            index,
          })
        );
      },
    },
  },

  methods: {
    search() {
      const type: string = this.$data.searchFilter.type;
      const value = this.$data.searchFilter.value;
      const filter: {
        [key: string]: string | boolean;
      } = { enabled: this.status === 'ACTIVE' };
      filter[type] = type === 'roleIn' ? [value] : value;
      console.log(filter);
      this.$data.filter = filter;
    },
    reset() {
      this.$data.filter = this.$data.defaultFilter;
    },
    setPage(val: number) {
      this.$data.currentPage = val;
    },
    computePages,
    checkAll() {
      this.$data.checked = !this.$data.checked;
      if (this.$data.checked) {
        const inactiveAccounts = this.$data.accounts.filter(
          (item: { status: string }) => item.status === 'INACTIVE'
        );
        for (var index = 0; index < inactiveAccounts.length; index++) {
          this.$data.checkedAccounts.push({
            id: (inactiveAccounts[index] as AuthorityAccount).id,
            index,
          });
        }
        console.log(this.$data.checkedAccounts);
      } else {
        this.$data.checkedAccounts = [];
      }
    },
    deletionWarning() {
      this.$data.warningModal = true;
    },
    deleteMordal() {
      const deleteCheckedAccounts = async () => {
        await accountService.deleteCheckedAccounts(this.$data.checkedAccounts);
        const unCheckedAccounts = this.$data.accounts.filter(
          (account: AuthorityAccount) =>
            this.$data.checkedAccounts.every(
              (checked: AuthorityAccount) => checked.id !== account.id
            )
        );
        this.$data.accounts = unCheckedAccounts;
      };
      this.$modal.show(
        { title: '정말 삭제하시겠습니까?', type: 'warning' },
        deleteCheckedAccounts
      );
    },

    updateAccount(item: any) {
      this.$router
        .push({
          name: 'Update Account',
          params: {
            id: item.id,
            name: item.name,
            department: item.department,
            rolesOfUser: item.roles,
            activated: item.activated,
            status: item.status,
            email: item.email,
            activatedAt: item.activatedAt,
            expiredAt: item.expiredAt,
          },
        })
        .catch((error: Error) => {
          if (error.name != 'NavigationDuplicated') {
            throw error;
          }
        });
      console.log(item);
    },
    getBadge(status: string) {
      switch (status) {
        case 'ACTIVE':
          return 'success';
        case 'INACTIVE':
          return 'secondary';
        default:
          'primary';
      }
    },
  },
});
