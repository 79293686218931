import gql from 'graphql-tag';

//Queries
export const getPermissions = gql`
  query getPermissions(
    $page: Int
    $pageSize: Int
    $filter: PermissionFilterParam
  ) {
    authority {
      permissions(page: $page, pageSize: $pageSize, filter: $filter) {
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            name
            description
            status
            items {
              type
              field
            }
          }
        }
      }
    }
  }
`;

export const getPermission = gql`
  query getPermission($id: ID!) {
    authority {
      permission(id: $id) {
        id
        name
        description
        status
        items {
          id
          type
          field
        }
      }
    }
  }
`;

export const getMyAllPermissions = gql`
  query getMyAllPermissions {
    authority {
      myAllPermissions {
        id
        items {
          id
          type
          field
        }
      }
    }
  }
`;

//Mutations

export const createPermission = gql`
  mutation createPermission($param: PermissionParam!) {
    createPermission(param: $param) {
      id
    }
  }
`;

export const updatePermission = gql`
  mutation updatePermission($param: PermissionParam!) {
    updatePermission(param: $param) {
      id
    }
  }
`;

export const enablePermission = gql`
  mutation enablePermission($id: ID!) {
    enablePermission(id: $id) {
      id
    }
  }
`;

export const disablePermission = gql`
  mutation disablePermission($id: ID!) {
    disablePermission(id: $id) {
      id
    }
  }
`;
