import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { AuthorityAccount, AuthorityAccountParam } from '../model/account';
import { AuthorityRole } from '../model/role';
import {
  deleteAccount,
  createAccount,
  attachRolesToAccount,
  updateAccount,
  enableAccount,
  disableAccount,
  detachRolesFromAccount,
  applyTermOfAccount,
} from '../graphqls/account';

export class AccountService {
  private readonly apollo: ApolloClient<NormalizedCacheObject>;
  constructor(apollo: ApolloClient<NormalizedCacheObject>) {
    this.apollo = apollo;
  }
  public async deleteCheckedAccounts(checkedAccounts: AuthorityAccount[]) {
    for (const index in checkedAccounts) {
      await this.delete(checkedAccounts[index]);
    }
  }
  public async delete(account: AuthorityAccount) {
    const res = await this.apollo.mutate({
      mutation: deleteAccount,
      variables: {
        id: account.id,
      },
    });
  }
  public async create(
    param: AuthorityAccountParam,
    selectedRoles: AuthorityRole[]
  ) {
    const res1 = await this.apollo.mutate({
      mutation: createAccount,
      variables: {
        param,
      },
    });
    console.log(res1);
    if (selectedRoles.length > 0) {
      this.attachRoles(param.id, selectedRoles);
    }
  }
  public async updateBaseInfo(param: AuthorityAccountParam) {
    await this.apollo.mutate({
      mutation: updateAccount,
      variables: {
        param,
      },
    });
  }
  public async disable(id: string) {
    await this.apollo.mutate({
      mutation: disableAccount,
      variables: {
        id,
      },
    });
  }
  public async enable(id: string) {
    await this.apollo.mutate({
      mutation: enableAccount,
      variables: {
        id,
      },
    });
  }
  public async attachRoles(id: string, roles: AuthorityRole[]) {
    await this.apollo.mutate({
      mutation: attachRolesToAccount,
      variables: {
        accountId: id,
        roleIds: roles.map((role: AuthorityRole) => role.id),
      },
    });
  }
  public async detachRoles(id: string, roles: AuthorityRole[]) {
    await this.apollo.mutate({
      mutation: detachRolesFromAccount,
      variables: {
        accountId: id,
        roleIds: roles.map((role: AuthorityRole) => role.id),
      },
    });
  }
  public async applyTerm(id: string, activatedAt: Date, expiredAt: Date) {
    await this.apollo.mutate({
      mutation: applyTermOfAccount,
      variables: {
        id,
        activatedAt,
        expiredAt,
      },
    });
  }
}
